import React, { useState } from "react"
import { graphql } from "gatsby"
import axios from "axios"

import { AuthProvider, AuthConsumer } from "../components/utils/AuthContext"
// import LoadCloudinary from "../components/utils/loadCloudinary"
import Seo from "../components/seo"
import Layout from "../components/layout"

export const query = graphql`
  {
    allStrapiCategory {
      nodes {
        id
        name
      }
    }
  }
`

const SubmitModal = ({ data }) => {
  const categories = data.allStrapiCategory.nodes

  const [image, setImage] = useState()
  const [submitted, setSubmitted] = useState(false)
  const [error, setError] = useState()

  const handleClose = () => {
    document.querySelector(".submit-modal").classList.toggle("is-active")
  }

  const validateLink = e => {
    const ytRegex = /(?:youtu\.be\/|youtube(?:-nocookie)?\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})|youtube\.com\/playlist\?list=|youtube\.com\/user\//

    if (ytRegex.test(e.target.value)) {
      let ytLink = e.target.value.match(ytRegex)
      let videoThumbnail = "https://img.youtube.com/vi/" + ytLink[1] + "/0.jpg"

      setImage(videoThumbnail)
      document.getElementById("submit-category").value =
        "5f7b242fc011ca00170ea070"
      // console.log(ytLink)
      // console.log(videoThumbnail)
    } else {
      setImage(null)
    }
  }

  const submitResource = e => {
    e.preventDefault()
    const button = e.target
    button.classList.add("is-loading")
    console.log(JSON.parse(localStorage.getItem("user")).id)

    const data = {
      url: document.getElementById("submit-link").value,
      title: document.getElementById("submit-title").value,
      media: image ? image.secure_url : image,
      category: document.getElementById("submit-category").value,
      description: document.getElementById("submit-description").value,
      content: document.getElementById("submit-content").value,
      submitted_by: JSON.parse(localStorage.getItem("user")).id,
      published_at: new Date(),
      approved: false,
    }

    console.log(data)

    axios
      .post(process.env.API_URL + "/articles", data)
      .then(function (res) {
        console.log(res.data)
        axios
          .post(process.env.API_URL + "/email", {
            to: "radu@winspire.co",
            from: "radu@winspire.co",
            replyTo: "radu@winspire.co",
            subject: "New resource incoming!",
            text: JSON.stringify(data),
            html: JSON.stringify(data),
          })
          .then(function (res) {
            console.log(res.data)
          })
          .catch(function (error) {
            console.log(error)
          })
        button.classList.remove("is-loading")
        setSubmitted(true)
      })
      .catch(function (error) {
        console.log(error)
        button.classList.remove("is-loading")
        setError(true)
      })
  }

  const myWidget = () => {
    window.cloudinary.openUploadWidget(
      {
        cloudName: "dg32onasj",
        uploadPreset: "ml_default",
      },
      (error, result) => {
        if (!error && result && result.event === "success") {
          console.log("Done! Here is the image info: ", result.info)
          setImage(result.info)
        }
      }
    )
  }

  const handleCloudinary = e => {
    e.preventDefault()
    myWidget()
  }

  return (
    <AuthProvider>
      <AuthConsumer>
        {({ login }) => (
          <Layout>
            <Seo title={"Submit a new resource!"} />
            {/* <LoadCloudinary /> */}

            <section className="hero is-dark is-small">
              <div className="hero-body">
                <div className="container pb-6 ">
                  <div className="columns is-centered mt-6">
                    <div className="column is-7 has-text-centered">
                      <h1 className="is-size-3 has-text-weight-bold has-text-white title-font">
                        Submit a new resource
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <div className="container">
              <div className="columns is-centered">
                <div className="column is-8">
                  {!submitted ? (
                    <form action="">
                      <section className="modal-card-body">
                        <div className="content">
                          <p className="has-text-white has-background-dark py-3 px-3">
                            You can submit a resource that you like or even your
                            own resource. We're going to have a look over it and
                            include it in our awesome list.
                          </p>
                          <div>
                            <div className="field">
                              <label
                                className="label is-size-7"
                                htmlFor="submit-link"
                              >
                                Link
                              </label>
                              <div className="control">
                                <input
                                  className="input"
                                  type="text"
                                  id="submit-link"
                                  placeholder="https://resourcelink.com"
                                  onChange={e => validateLink(e)}
                                />
                              </div>
                            </div>

                            <div className="field mt-5">
                              <label
                                className="label is-size-7"
                                htmlFor="submit-title"
                              >
                                Title
                              </label>
                              <div className="control">
                                <input
                                  className="input"
                                  type="text"
                                  id="submit-title"
                                  placeholder="My awesome resource title"
                                />
                              </div>
                            </div>

                            <div className="field mt-5">
                              <label
                                className="label is-size-7"
                                htmlFor="submit-image"
                              >
                                Image
                              </label>
                              <div className="control">
                                <div className="file is-medium is-boxed">
                                  <label
                                    className="file-label"
                                    style={{ width: "100%" }}
                                    id="submit-image"
                                  >
                                    {image ? (
                                      <figure className="image is-16by9 mx-0 my-0">
                                        <img
                                          src={image.secure_url || image}
                                          alt=""
                                        />
                                      </figure>
                                    ) : (
                                      ""
                                    )}
                                    <input
                                      className="file-input"
                                      type="file"
                                      name="resume"
                                      id="submit-image"
                                      onClick={e => handleCloudinary(e)}
                                    />
                                    <span className="file-cta">
                                      <span className="file-icon">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 50 50"
                                          className="is-24"
                                        >
                                          <path d="M43,32.768V15.602L29.398,2H7v46h31v2h7v-9h5L43,32.768z M30,5.398l9.586,9.586H30V5.398z M13,22h20v2H13V22z M13,28h16v2 H13V28z M13,34h20v2H13V34z M43,39v9h-3v-9h-2.602l4.098-5l4.105,5H43z" />
                                        </svg>
                                      </span>
                                      <span className="file-label">
                                        {image
                                          ? "Replace image"
                                          : "Choose an image"}
                                      </span>
                                    </span>
                                  </label>
                                </div>
                              </div>
                            </div>

                            <div className="field mt-5">
                              <label
                                className="label is-size-7"
                                htmlFor="submit-category"
                              >
                                Category
                              </label>
                              <div className="control">
                                <div className="select is-fullwidth">
                                  <select id="submit-category">
                                    {categories
                                      ? categories.map((category, index) => {
                                          return (
                                            <option
                                              key={index}
                                              value={category.id.split("_")[1]}
                                            >
                                              {category.name}
                                            </option>
                                          )
                                        })
                                      : ""}
                                  </select>
                                </div>
                              </div>
                            </div>

                            <div className="field mt-5">
                              <label
                                className="label is-size-7"
                                htmlFor="submit-description"
                              >
                                Short description
                              </label>
                              <p className="is-size-6">
                                Tell us what this is about in 10-15 words
                              </p>
                              <div className="control">
                                <input
                                  className="input"
                                  type="text"
                                  id="submit-description"
                                  placeholder="A resource for all managers to handle their employees better"
                                />
                              </div>
                            </div>

                            <div className="field mt-5">
                              <label
                                className="label is-size-7"
                                htmlFor="submit-content"
                              >
                                Content
                              </label>
                              <p className="is-size-6">
                                Here you can describe more freely what this is
                                about and why it is awesome
                              </p>
                              <div className="control">
                                <textarea
                                  className="textarea"
                                  id="submit-content"
                                  placeholder="Resource is great because..."
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        {error ? (
                          <span className="tag is-medium is-danger">
                            Please check that all the inputs are correctly
                            filled in.
                          </span>
                        ) : (
                          ""
                        )}
                      </section>

                      <footer className="modal-card-foot">
                        <button
                          className="button"
                          onClick={() => handleClose()}
                        >
                          Cancel
                        </button>
                        <button
                          className="button is-primary"
                          onClick={e => submitResource(e)}
                        >
                          Submit
                        </button>
                      </footer>
                    </form>
                  ) : (
                    <section className="section content">
                      <p>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 50 50"
                          className="is-24 is-success top-4px mr-1"
                        >
                          <path
                            d="M25,2C12.318,2,2,12.318,2,25c0,12.683,10.318,23,23,23c12.683,0,23-10.317,23-23C48,12.318,37.683,2,25,2z M35.827,16.562 L24.316,33.525l-8.997-8.349c-0.405-0.375-0.429-1.008-0.053-1.413c0.375-0.406,1.009-0.428,1.413-0.053l7.29,6.764l10.203-15.036 c0.311-0.457,0.933-0.575,1.389-0.266C36.019,15.482,36.138,16.104,35.827,16.562z"
                            fill="#8FC238"
                          />
                        </svg>
                        Thank you for contributing!
                      </p>
                      <p>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 50 50"
                          className="is-24 is-success top-4px mr-1"
                        >
                          <path
                            d="M25,2C12.318,2,2,12.318,2,25c0,12.683,10.318,23,23,23c12.683,0,23-10.317,23-23C48,12.318,37.683,2,25,2z M35.827,16.562 L24.316,33.525l-8.997-8.349c-0.405-0.375-0.429-1.008-0.053-1.413c0.375-0.406,1.009-0.428,1.413-0.053l7.29,6.764l10.203-15.036 c0.311-0.457,0.933-0.575,1.389-0.266C36.019,15.482,36.138,16.104,35.827,16.562z"
                            fill="#8FC238"
                          />
                        </svg>
                        In the shortest time, we'll have a look at it and post
                        it to our list. As soon as we do so, we'll email you and
                        let you know!
                      </p>
                    </section>
                  )}
                </div>
              </div>
            </div>
          </Layout>
        )}
      </AuthConsumer>
    </AuthProvider>
  )
}

export default SubmitModal
